import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Grid, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { Container, StepperContent } from './styled';
import { BasicInformations, Final, FinancialInformations, SupplierInformation } from './steppers';
import { goToBack, goToListAllContracts } from '../../../../router/coordinator';
import { BasePage } from '../../../../components/BaseMain';
import { AlertMessage, Loading, TitlePages, TopBackArea } from '../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../utils';
import { useForm } from '../../../../hooks/useForm';
import { findContract } from '../../../../services';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { PersonType, Values } from '../../../../utils/enums';

const INITAL_STEP = 0;
const FINAL_STEP = 3;

export function EditContractPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Editar' }
  ];

  const [form, _, __, setForm] = useForm({});

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  // -- Lógica do step
  const [activeStep, setActiveStep] = useState(0);
  const [stepRegisterContract, setStepRegisterContract] = useState(1);
  const [isRegisterContractSuccess, setIsRegisterContractSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => await init(), []);
  const init = async () => {
    try {
      const { indexInitial, indexHistoryInitial, financialContract, provider, cities, ...result } =
        await findContract(uuid);

      const contract = {
        uuid_contract: result.uuid,

        // Informações básicas
        title: result.title?.toTitleCase?.(),
        service: result.service.toTitleCase?.(),
        uuid_sector: result.sector?.uuid,
        uuid_segment: result.segments?.uuid,
        uuid_index: indexInitial?.uuid || Values.NO_INDEX,
        uuid_history_index: indexHistoryInitial?.uuid,
        index_value: indexHistoryInitial?.percent,
        readjustment_periodicity: financialContract?.readjustmentPeriodicity,
        payment_periodicity: financialContract?.paymentPeriodicity,
        cities: cities?.length
          ? cities?.map((city) => ({ uuid_city: city?.uuid, uuid_state: city?.state?.uuid }))
          : [{ uuid_city: '', uuid_state: '' }],

        // Informações financeiras
        credit: financialContract?.credit,
        debit: financialContract?.debit,
        readjustment_month: financialContract?.readjustmentMonth,
        uuid_coin: financialContract?.coin?.uuid,
        uuid_coin_history: financialContract?.coinHistoryInitial?.uuid,
        total_installments: financialContract?.totalInstallments,
        installments_paid: financialContract?.installmentsPaid,
        initial_value:
          financialContract?.initialValue &&
          (financialContract?.initialValue / 100).toLocaleString('pt-BR'),
        late_fee: financialContract?.lateFee?.toLocaleString('pt-BR'),
        fine_for_salary: financialContract?.salaryPenalty?.toLocaleString('pt-BR'),
        days_rescission_fine: financialContract?.rescissionFine,
        late_interest: financialContract?.lateInterest?.toLocaleString('pt-BR'),

        exchang_type: financialContract?.coinHistoryInitial?.type,
        exchang_value: financialContract?.coinHistoryInitial?.exchangeInReal
          ? (financialContract?.coinHistoryInitial?.exchangeInReal / 100).toLocaleString('pt-BR')
          : '',
        coin_symbol: financialContract?.coin?.title,

        // Informações do fornecedor
        uuid_provider: provider?.uuid,
        document: provider?.document,
        name: provider?.name,
        uuid_industry: provider?.industry?.uuid,
        acronym: provider?.acronym,
        is_intern: provider?.isIntern,
        domain: provider?.domain,
        personType: provider?.document?.length === 14 ? PersonType.PJ : PersonType.PF,

        // Documento
        is_confidential: result.isConfidential,
        is_signed: true,
        observation: result.observation
      };

      const initDate = dayjs(result.initDate, 'YYYY-MM-DDTHH:mm');
      if (initDate.isValid()) {
        contract.init_date = initDate;
      }

      const indexDate = dayjs(result.indexHistoryInitial?.date, 'YYYY-MM-DDTHH:mm');
      if (indexDate.isValid()) {
        contract.index_date = indexDate;
      }

      const expirationDate = dayjs(result.expirationDate, 'YYYY-MM-DDTHH:mm');
      if (expirationDate.isValid()) {
        contract.expiration_date = expirationDate;
      }

      if (result.financialContract?.coinHistoryInitial?.date) {
        const exchargeDate = dayjs(
          result.financialContract?.coinHistoryInitial?.date,
          'YYYY-MM-DD'
        );
        if (expirationDate.isValid()) {
          contract.exchange_date = exchargeDate;
        }
      } else {
        contract.is_custom_coin = true;
      }

      setForm(contract);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error?.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const onNextStep = () => {
    if (isRegisterContractSuccess) {
      setIsRegisterContractSuccess(false);
    }

    if (activeStep !== FINAL_STEP) {
      setActiveStep(activeStep + 1);
    }
  };

  const onBackStep = () => {
    if (activeStep !== INITAL_STEP) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleStep = () => {
    const steps = {
      0: (
        <BasicInformations
          onNextStep={onNextStep}
          setForm={setForm}
          allForm={form}
          isSuccess={isRegisterContractSuccess}
        />
      ),
      1: (
        <FinancialInformations
          onNextStep={onNextStep}
          setForm={setForm}
          onBackStep={onBackStep}
          allForm={form}
        />
      ),
      2: (
        <SupplierInformation
          onNextStep={onNextStep}
          setForm={setForm}
          onBackStep={onBackStep}
          allForm={form}
        />
      ),
      3: (
        <Final
          onBackStep={onBackStep}
          setForm={setForm}
          allForm={form}
          lastStep={stepRegisterContract}
          setLastStep={setStepRegisterContract}
        />
      )
    };

    return steps[activeStep];
  };
  // --

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} />

      <Loading loading={loading} />

      <Grid item xs={12}>
        <AlertMessage
          title={alertMessage?.title}
          message={alertMessage?.message}
          severity={alertMessage?.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Container>
        <TitlePages toHideDivider={true} title={'Editar contrato'} />

        <Stepper
          id="stepper"
          activeStep={activeStep}
          alternativeLabel={isDesktop()}
          orientation={!isDesktop() ? 'vertical' : 'horizontal'}
        >
          <Step>
            <StepLabel>Informações básicas</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Informações financeiras</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Informações sobre o fornecedor</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Envio de documento e validações finais</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
        </Stepper>

        <StepperContent>{isDesktop() && handleStep()}</StepperContent>
      </Container>
    </BasePage>
  );
}
