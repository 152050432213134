import { Button, Grid, MenuItem } from '@mui/material';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  TextFieldKeyPress,
  TextFieldNextFocus
} from '../../../../../../../components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGroup } from '../../styled';
import { clearAlertMessage, isDesktop } from '../../../../../../../utils';
import { GENERIC_ERROR } from '../../../../../../../utils/constantes';
import {
  getItemLocalStorage,
  listCities,
  listIndexes,
  listSectors,
  listSegments,
  listStates,
  removeItemLocalStorage,
  setItemLocalStorage
} from '../../../../../../../services';
import { LocalStorageKeys, Values } from '../../../../../../../utils/enums';
import { InformativeText } from '../../../../../../../components/InformativeText';
import { useForm } from '../../../../../../../hooks/useForm';
import { validateMandatoryInput } from '../../../../../../../utils/validates';
import { useUpdateState } from '../../../../../../../hooks/useUpdateState';
import AutocompleteSearch from '../../../../../../../components/AutocompleteSearch';
import { ButtonAdd, Scroll } from './styled';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CityCard from '../../../../../../../components/CityCard';

export function BasicInformations({ onNextStep }) {
  const isDesktopView = isDesktop();
  const [form, onChange, _, setForm] = useForm({
    title: '',
    service: '',
    uuid_sector: '',
    uuid_segment: '',
    uuid_index: '',
    cities: [{ uuid_state: '', uuid_city: '' }]
  });
  const [{ indexes, loading }, setStates] = useUpdateState({
    loading: true,
    indexes: []
  });

  const [errorsValidation, setErrorsValidation] = useState({});
  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      getExistForm();

      if (form.isSuccess) {
        setAlertMessage({
          severity: 'success',
          title: `Contrato cadastrado com sucesso.`,
          message: ''
        });
        removeItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT);
      }

      const indexes = await listIndexes();

      setStates({ indexes });
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error.trace && `Código: ${error.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, [form.isSuccess]);

  useEffect(() => {
    init();
  }, [init]);

  const getExistForm = () => {
    const saveForm = getItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT);
    if (saveForm) {
      const { company_uuid: uuidCompany } = getItemLocalStorage(LocalStorageKeys.USER);

      if (saveForm?.company_uuid === uuidCompany) {
        setForm({ ...saveForm, company_uuid: uuidCompany });
      }
    }
  };

  const disabledButton = () => {
    const mandatory = ['title', 'service', 'uuid_sector', 'uuid_segment', 'uuid_index'];
    const fields = Object.keys(form);

    const allRequiredFieldsFilled = fields.every((field) =>
      mandatory.includes(field) ? form[field] && !errorsValidation[field] : true
    );

    return !allRequiredFieldsFilled;
  };

  const nextStep = () => {
    if (!form.company_uuid) {
      const { company_uuid: company } = getItemLocalStorage(LocalStorageKeys.USER);
      form.company_uuid = company;
    }

    setItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT, form);
    onNextStep();
  };

  const getSegments = useCallback(async () => {
    try {
      const data = await listSegments();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getSectors = useCallback(async () => {
    try {
      const data = await listSectors();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getStates = useCallback(async () => {
    try {
      const data = await listStates();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getCities = useCallback(async (uuidState) => {
    try {
      if (uuidState) {
        const data = await listCities(uuidState);
        return data;
      }

      return [];
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const handleCityChange = useCallback(
    (event, index) => {
      const { name, value } = event?.target || {};

      const listCities = structuredClone(form?.cities);
      listCities[index] = {
        ...listCities[index],
        [name]: value
      };

      setForm((prev) => ({
        ...prev,
        cities: listCities
      }));
    },
    [form.cities, setForm]
  );

  const addCity = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      cities: [...prev.cities, { uuid_state: '', uuid_city: '' }]
    }));
  }, [form]);

  const removeCity = useCallback(
    (index) => {
      const updatedCities = form?.cities?.filter?.((_, i) => i !== index);
      setForm((prev) => ({
        ...prev,
        cities: updatedCities
      }));
    },
    [form.cities, setForm]
  );

  const renderCities = useMemo(() => {
    return form.cities?.map?.((city, index) => (
      <CityCard
        key={index}
        city={city}
        index={index}
        quantityItems={form?.cities?.length}
        isDesktopView={isDesktopView}
        handleChange={handleCityChange}
        removeCity={removeCity}
        getStates={getStates}
        getCities={getCities}
      />
    ));
  }, [form.cities, handleCityChange, removeCity, isDesktopView]);

  return (
    <Grid container xs={12} rowGap={2} columnSpacing={isDesktopView ? 2 : 0}>
      <Loading loading={loading} />

      <Grid item xs={12}>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          label="Título"
          name="title"
          value={form.title}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('title', form, setErrorsValidation)}
          error={errorsValidation.title}
          helperText={errorsValidation.title}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          label="Serviço"
          name="service"
          value={form.service}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('service', form, setErrorsValidation)}
          error={errorsValidation.service}
          helperText={errorsValidation.service}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AutocompleteSearch
          required
          label="Setor"
          name="uuid_sector"
          value={form.uuid_sector}
          onChange={onChange}
          optionLabel={['title']}
          getListDataCallback={getSectors}
          formatLabel={(label) => label?.toTitleCase?.()}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AutocompleteSearch
          required
          label="Segmento"
          name="uuid_segment"
          value={form.uuid_segment}
          onChange={onChange}
          optionLabel={['title']}
          getListDataCallback={getSegments}
          formatLabel={(label) => label?.toTitleCase?.()}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextFieldKeyPress
          select
          label="Índice"
          name="uuid_index"
          value={form.uuid_index}
          onChange={onChange}
        >
          <MenuItem value={Values.NO_INDEX}>Sem índice</MenuItem>
          {indexes.map((i) => (
            <MenuItem key={i.uuid} value={i.uuid}>
              {i.name?.toUpperCase()}
            </MenuItem>
          ))}
        </TextFieldKeyPress>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Locais de prestação de serviço">
          <Scroll>{renderCities}</Scroll>
          <ButtonAdd fullWidth variant="text" Icon={AddCircleIcon} onClick={addCity}>
            Adicionar cidade
          </ButtonAdd>
        </BorderWithText>
      </Grid>

      <InformativeText />

      <ButtonGroup className="buttonGroup oneButton nearTheTop">
        <Button disabled={disabledButton()} onClick={nextStep}>
          Próximo
        </Button>
      </ButtonGroup>
    </Grid>
  );
}
