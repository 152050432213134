import { Button, Grid, MenuItem } from '@mui/material';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  TextFieldKeyPress,
  TextFieldNextFocus
} from '../../../../../../components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGroup } from '../../styled';
import { clearAlertMessage, isDesktop } from '../../../../../../utils';
import { GENERIC_ERROR } from '../../../../../../utils/constantes';
import {
  listCities,
  listIndexes,
  listSectors,
  listSegments,
  listStates
} from '../../../../../../services';
import { Values } from '../../../../../../utils/enums';
import { InformativeText } from '../../../../../../components/InformativeText';
import AutocompleteSearch from '../../../../../../components/AutocompleteSearch';
import { useForm } from '../../../../../../hooks/useForm';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ButtonAdd, Scroll } from './styled';
import CityCard from '../../../../../../components/CityCard';

export function BasicInformations({ onNextStep, setForm, allForm }) {
  const isDesktopView = isDesktop();
  const initialForm = {
    title: '',
    service: '',
    uuid_sector: '',
    uuid_segment: '',
    uuid_index: '',
    uuid_state: '',
    uuid_city: '',
    cities: [{ uuid_state: '', uuid_city: '' }]
  };

  const [basicInformations, onChange, _, setBasicInformations] = useForm(initialForm);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [indexes, setIndexes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const indexes = await listIndexes();
      setIndexes(indexes);
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const getExistForm = () => {
    for (const key in basicInformations) {
      setBasicInformations((prevValue) => ({
        ...prevValue,
        [key]: allForm[key] || initialForm[key]
      }));
    }
  };

  useEffect(() => {
    getExistForm();
  }, [allForm]);

  useEffect(() => {
    init();
  }, [init]);

  /**
   * Gera erro se um input obrigatório não está preenchido
   * @param {*} field - campo a ser verificado
   * @returns
   */
  const validateMandatoryInput = (field) => {
    if (!basicInformations[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const disabledButton = () => {
    const mandatory = ['title', 'service', 'uuid_sector', 'uuid_segment', 'uuid_index'];
    const fields = Object.keys(basicInformations);

    const allRequiredFieldsFilled = fields.every((field) =>
      mandatory.includes(field) ? basicInformations[field] && !errorsValidation[field] : true
    );

    return !allRequiredFieldsFilled;
  };

  const nextStep = () => {
    setForm((prevForm) => ({ ...prevForm, ...basicInformations }));
    onNextStep();
  };

  const getSegments = useCallback(async () => {
    try {
      const data = await listSegments();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getSectors = useCallback(async () => {
    try {
      const data = await listSectors();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getStates = useCallback(async () => {
    try {
      const data = await listStates();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getCities = useCallback(
    async (uuidState) => {
      try {
        if (uuidState) {
          const data = await listCities(uuidState);
          return data;
        }

        return [];
      } catch (error) {
        setAlertMessage({
          title: error?.message || GENERIC_ERROR,
          message: error?.trace && `Código: ${error?.trace}`
        });
        return [];
      }
    },
    [basicInformations]
  );

  const handleCityChange = (event, index) => {
    const { name, value } = event?.target || {};

    const listCities = [...basicInformations.cities];
    listCities[index] = {
      ...listCities[index],
      [name]: value
    };

    setBasicInformations((prev) => ({
      ...prev,
      cities: listCities
    }));
  };

  const addCity = useCallback(() => {
    setBasicInformations((prev) => ({
      ...prev,
      cities: [...prev.cities, { uuid_state: '', uuid_city: '' }]
    }));
  }, [setBasicInformations]);

  const removeCity = useCallback(
    (index) => {
      const updatedCities = basicInformations.cities.filter((_, i) => i !== index);
      setBasicInformations((prev) => ({
        ...prev,
        cities: updatedCities
      }));
    },
    [basicInformations.cities, setBasicInformations]
  );

  const renderCities = useMemo(() => {
    return basicInformations.cities.map((city, index) => (
      <CityCard
        key={index}
        city={city}
        index={index}
        quantityItems={basicInformations?.cities?.length}
        isDesktopView={isDesktopView}
        handleChange={handleCityChange}
        removeCity={removeCity}
        getStates={getStates}
        getCities={getCities}
      />
    ));
  }, [basicInformations.cities, handleCityChange, removeCity, isDesktopView]);

  return (
    <Grid container xs={12} rowGap={2} columnSpacing={isDesktopView ? 2 : 0}>
      <Loading loading={loading} />

      <Grid item xs={12}>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          label={'Título'}
          name={'title'}
          value={basicInformations.title}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('title')}
          error={errorsValidation.title}
          helperText={errorsValidation.title && 'O título é obrigatório'}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          label={'Serviço'}
          name={'service'}
          value={basicInformations.service}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('service')}
          error={errorsValidation.service}
          helperText={errorsValidation.service && 'O serviço é obrigatório'}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AutocompleteSearch
          required
          label="Setor"
          name="uuid_sector"
          value={basicInformations.uuid_sector}
          onChange={onChange}
          optionLabel={['title']}
          getListDataCallback={getSectors}
          formatLabel={(label) => label?.toTitleCase?.()}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AutocompleteSearch
          required
          label="Segmento"
          name="uuid_segment"
          value={basicInformations.uuid_segment}
          onChange={onChange}
          optionLabel={['title']}
          getListDataCallback={getSegments}
          formatLabel={(label) => label?.toTitleCase?.()}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextFieldKeyPress
          required
          select
          label="Índice"
          name="uuid_index"
          value={basicInformations.uuid_index}
          onChange={onChange}
        >
          <MenuItem value={Values.NO_INDEX}>Sem índice</MenuItem>
          {indexes.map((i) => (
            <MenuItem key={i.uuid} value={i.uuid}>
              {i.name?.toUpperCase()}
            </MenuItem>
          ))}
        </TextFieldKeyPress>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Locais de prestação de serviço">
          <Scroll>{renderCities}</Scroll>
          <ButtonAdd fullWidth variant="text" Icon={AddCircleIcon} onClick={addCity}>
            Adicionar cidade
          </ButtonAdd>
        </BorderWithText>
      </Grid>

      <InformativeText />

      <ButtonGroup className="buttonGroup oneButton nearTheTop">
        <Button disabled={disabledButton()} onClick={nextStep}>
          Próximo
        </Button>
      </ButtonGroup>
    </Grid>
  );
}
