import { useNavigate, useParams } from 'react-router-dom';
import {
  goToBack,
  goToDatailsContract,
  goToListAdicionalsContract,
  goToListAllContracts
} from '../../../../../router/coordinator';
import { useUpdateState } from '../../../../../hooks/useUpdateState';
import { useForm } from '../../../../../hooks/useForm';
import { useCallback, useEffect, useState } from 'react';
import {
  editAdditionalContract,
  findAdditionalContract,
  listContractTypes,
  listIndexes
} from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { BasePage } from '../../../../../components/BaseMain';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages,
  UploadButton
} from '../../../../../components';
import {
  clearAlertMessage,
  handleCoin,
  onChangeDate,
  onChangeMoney,
  toCent
} from '../../../../../utils';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import AutocompleteSearch from '../../../../../components/AutocompleteSearch';
import { DatePicker } from '@mui/x-date-pickers';
import { InformativeText } from '../../../../../components/InformativeText';
import { Content } from '../AddAditionalContractPage/styled';
import dayjs from 'dayjs';
import { CoinArea } from '../../EditContractPage/steppers/step2/areas';

export function EditAdditionalContractPage() {
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [isCoinCustom, setIsCoinCustom] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const { uuidAdditionalContract, uuidContract } = useParams();
  const navigate = useNavigate();

  if (!uuidAdditionalContract) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsContract(navigate, uuidContract) },
    {
      title: 'Adicionais',
      functionCoordinator: () => goToListAdicionalsContract(navigate, uuidContract)
    },
    { title: 'Editar' }
  ];

  const [{ loading, additionalContract, additionalContractTypes, openModal }, setStates] =
    useUpdateState({
      loading: false,
      additionalContract: {},
      additionalContractTypes: [],
      coin: '',
      localValues: { initialDate: '', expirationDate: '' },
      openModal: false
    });
  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [form, onChange, _, setForm] = useForm({
    uuid_additional_contract_type: '',
    uuid_index: '',
    new_amount: '',
    init_date: null,
    expiration_date: null,
    observation: '',

    uuid_coin: '',
    uuid_coin_history: '',
    exchange_date: null,
    exchang_type: ''
  });

  const init = useCallback(async () => {
    try {
      setStates({ loading: true });

      isCustomCoin();

      const [additionalContract, types] = await Promise.all([
        findAdditionalContract(uuidAdditionalContract),
        listContractTypes()
      ]);

      setStates({
        additionalContract,
        additionalContractTypes: types,
        currencySymbol: additionalContract?.coin?.title,
        coin: additionalContract?.coin?.title
      });
      const formData = {
        uuid_additional_contract_type: additionalContract?.contractType?.uuid,
        uuid_coin: additionalContract?.coin?.uuid,
        exchange_date:
          additionalContract?.coinHistory &&
          additionalContract?.coinHistory?.date &&
          dayjs(additionalContract?.coinHistory?.date),
        uuid_index: additionalContract?.index?.uuid,
        new_amount: additionalContract?.newAmount
          ? (additionalContract?.newAmount / 100).toLocaleString('pt-BR')
          : '',
        exchang_type: additionalContract?.coinHistory?.type,
        observation: additionalContract?.observation,
        init_date: additionalContract?.initDate && dayjs(additionalContract?.initDate),
        expiration_date:
          additionalContract?.expirationDate && dayjs(additionalContract?.expirationDate)
      };

      setForm((prev) => ({
        ...prev,
        ...formData
      }));

      setCurrencySymbol(additionalContract?.coin?.title || 'R$');
      setDocumentName(additionalContract?.pathFileName);
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const handleOpen = () => setStates({ openModal: true });
  const handleClose = () => setStates({ openModal: false });

  const updateContract = async () => {
    try {
      setStates({ loading: true });
      handleClose();

      const {
        uuid_additional_contract_type: uuidAdditionalContractType,
        init_date: initDate,
        new_amount: newAmount,
        expiration_date: expirationDate,
        uuid_coin: uuidCoin,
        uuid_coin_history: uuidCoinHistory,
        uuid_index: uuidIndex,
        observation: observation
      } = form;

      const updatedAmount = newAmount && toCent(newAmount);

      await editAdditionalContract(
        uuidAdditionalContract,
        uuidAdditionalContractType,
        updatedAmount,
        uuidCoin,
        uuidCoinHistory,
        initDate,
        expirationDate,
        uuidIndex,
        document,
        observation
      );

      setAlertMessage({
        title: 'Contrato adicional atualizado com sucesso.',
        severity: 'success'
      });
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  };

  const disabledButton = () => {
    return !form.uuid_additional_contract_type;
  };

  const getIndexes = useCallback(async () => {
    try {
      const data = await listIndexes();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const isCustomCoin = () => {
    if (form.is_custom_coin !== undefined) {
      setIsCoinCustom(form.is_custom_coin);
    }
  };

  const handleUploadChange = (newDocument) => {
    setDocument(newDocument);
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Loading loading={loading} />
      <TitlePages
        title={`Editar adicional do contrato ${additionalContract?.contract?.id}`}
        description="Você pode editar qualquer campo, exceto o tipo de contrato."
      />

      <Content container spacing={2}>
        <AlertMessage
          message={alertMessage?.message}
          severity={alertMessage?.severity}
          title={alertMessage?.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
        <Grid item xs={12} lg={3}>
          <TextField
            select
            required
            name="uuid_additional_contract_type"
            value={form.uuid_additional_contract_type}
            onChange={onChange}
            label="Tipo"
            disabled
          >
            {additionalContractTypes?.map((c) => (
              <MenuItem key={c.uuid} value={c.uuid}>
                {c.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={3}>
          <AutocompleteSearch
            label="Índice"
            name="uuid_index"
            value={form.uuid_index}
            onChange={onChange}
            optionLabel={['name']}
            getListDataCallback={getIndexes}
            formatLabel={(label) => label?.toUpperCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data de início"
            name="init_date"
            value={form.init_date}
            onChange={(e) => onChangeDate(e, 'init_date', onChange)}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data de expiração"
            name="expiration_date"
            value={form.expiration_date}
            onChange={(e) => onChangeDate(e, 'expiration_date', onChange)}
          />
        </Grid>
        <Grid item xs={12}>
          <CoinArea
            form={form}
            setForm={setForm}
            onChange={onChange}
            coin={currencySymbol}
            updateStateGlobal={setStates}
            setCoin={setCurrencySymbol}
            setError={setAlertMessage}
            isCoinCustom={isCoinCustom}
            setIsCoinCustom={setIsCoinCustom}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <BorderWithText title="Valor">
            <Grid item xs={12}>
              <TextFieldNextFocus
                label="Novo valor"
                name="new_amount"
                onChange={(e) => onChangeMoney(e, onChange)}
                value={form.new_amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{handleCoin(currencySymbol)}</InputAdornment>
                  )
                }}
              />
            </Grid>
          </BorderWithText>
        </Grid>

        <Grid item xs={12} id="upload-button">
          <UploadButton
            text="Contrato em PDF *"
            noImageMassege="Nenhum documento selecionado."
            outImageName={documentName}
            setImage={handleUploadChange}
            setOutImageName={setDocumentName}
            noConvertToBase64
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldNextFocus
            label="Observação"
            multiline
            rows={4}
            onChange={onChange}
            name="observation"
            value={form.observation}
          />
        </Grid>

        <InformativeText />
        <Grid item className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Content>
      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={updateContract}
        title="Editar contrato adicional"
        text={`Tem certeza que deseja editar o contrato adicional ${additionalContract?.contract?.id}?`}
      />
    </BasePage>
  );
}
