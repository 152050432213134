import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { convertCentsToWhole } from '../../../../../../utils/convert';
import {
  goToEditAdditionalContract,
  goToVisualizeAdditionalContract
} from '../../../../../../router/coordinator';
import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonDesktop,
  TargetStatus
} from '../../../../../../components';
import { Order } from '../../../../../../utils/enums';
import { hasPermission } from '../../../../../../utils';
import MenuTable from '../../../../../../components/MenuActionsTable';
import EditIcon from '@mui/icons-material/Edit';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export function DesktopTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  order,
  orderBy,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder,
  uuidContract
}) {
  const goToVisualizeContract = (uuid) => {
    if (hasPermission('FIND_FILE_ADDITIONAL_CONTRACT')) {
      goToVisualizeAdditionalContract(navigate, uuid);
    }
  };

  const goToEditContract = (uuid, uuidContract) => {
    if (hasPermission('EDIT_ADDITIONAL_CONTRACT')) {
      goToEditAdditionalContract(navigate, uuid, uuidContract);
    }
  };

  const contractsList = contracts?.map((contract) => {
    return (
      <TableRow key={contract.id} className="not-effect">
        <TableCell>{contract.order && `${contract.order}°`}</TableCell>
        <TableCell>
          <TargetStatus status={contract.contractType?.title} noCircle variant="solid" />
        </TableCell>
        <TableCell>{contract.index?.name?.toUpperCase?.() || '-'}</TableCell>
        <TableCell>{contract.coin?.title?.toTitleCase?.() || '-'}</TableCell>
        <TableCell>{convertCentsToWhole(contract.coinHistory?.exchangeInReal) || '-'}</TableCell>
        <TableCell>{convertCentsToWhole(contract.newAmount) || '-'}</TableCell>
        <TableCell>{contract.initDate?.toMask('date') || '-'}</TableCell>
        <TableCell>{contract.expirationDate?.toMask('date') || '-'}</TableCell>
        <TableCell>{contract.observation || '-'}</TableCell>
        <HiddenForPermission keys={['FIND_FILE_ADDITIONAL_CONTRACT', 'EDIT_ADDITIONAL_CONTRACT']}>
          <TableCell>
            <MenuTable.Container>
              <HiddenForPermission keys="FIND_FILE_ADDITIONAL_CONTRACT">
                <MenuTable.Item
                  icon={ManageSearchIcon}
                  onClick={() => goToVisualizeContract(contract.uuid)}
                >
                  Visualizar
                </MenuTable.Item>
              </HiddenForPermission>
              <HiddenForPermission keys="EDIT_ADDITIONAL_CONTRACT">
                <MenuTable.Item
                  icon={EditIcon}
                  onClick={() => goToEditContract(contract.uuid, uuidContract)}
                >
                  Editar
                </MenuTable.Item>
              </HiddenForPermission>
            </MenuTable.Container>
          </TableCell>
        </HiddenForPermission>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={9} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="not-effect">
              <TableCell width="15px">
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'order'}
                  onClick={() => handleOrder('order')}
                >
                  Ordem
                </TableSortLabel>
              </TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Índice</TableCell>
              <TableCell>Moeda</TableCell>
              <TableCell>Cotação em real</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'initDate'}
                  onClick={() => handleOrder('initDate')}
                >
                  Data inicial
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'expirationDate'}
                  onClick={() => handleOrder('expirationDate')}
                >
                  Data de expiração
                </TableSortLabel>
              </TableCell>
              <TableCell>Observação</TableCell>
              <HiddenForPermission
                keys={['FIND_FILE_ADDITIONAL_CONTRACT', 'EDIT_ADDITIONAL_CONTRACT']}
              >
                <TableCell align="right">Ações</TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
